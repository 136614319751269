.fld-wrd-em{
	float:left;
	width:calc(100% - 110px);
}
.fld-wrd-emt{
	float:left;
	width:calc(100% - 40px);
}
.fld-wrd-emx{
	float:left;
	width:30px;
	margin-left:10px;
}
.fld-wrd-btn{
	float:left;
	width:100px;
	text-align:right;
	margin-left:10px;
}
.modal-content{
	border-radius:0;
	background-color:transparent;
	border:0px;
	padding: 0 15px;
}
.modal-body{
	background-color:#ffffff;
	padding:25px 20px 20px 20px;
	max-height:400px;
	overflow-y:auto;
}
.modal-footer{
	background-color:#ffffff;
}
.modal-dialog{
    max-width: 700px;
	margin: 4rem auto;
}
.modal-tab{
	display:flex;
}
.modal-tab-elm{
	position:relative;
	padding:10px 20px;
	border:1px solid #ccc;
	background-color:#f2f2f2;
	font-weight:400;
	font-size:1.1rem;
	border-right:0;
	cursor:pointer;
}
.modal-tab-sup{
	position:absolute;
	top:5px;
	right:8px;
	font-weight:600;
}
.modal-tab-elm-on{
	background-color:#fff;
	border-bottom:0;
	cursor:default;
}
.modal-tab-elm:last-child{
	flex-grow: 1;
	background:transparent;	
	border-top:0;
}
.modal-tab-plus{
	font-size:1.5rem;
	line-height:0.8;
	position:relative;
}
.modal-tab-mn{
	position:absolute;
	font-size:1.1rem;
	top:48px;
	right:-1px;
	width:150px;
	padding:5px 0;
	background-color:#fff;
	border:1px solid #ccc;
	max-height:150px;
	z-index:200;
}
.modal-tab-mn-elm{
	padding:10px;	
	cursor:pointer;
}
.modal-tab-mn-elm:hover{
	background-color:#f2f2f2;	
}