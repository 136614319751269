.sub-bd{
    background-color:#ffffff;
	min-height:300px;
	padding:15px 10px 50px 10px;
}
.sub-sbd{
    background-color:#f2f2f2;
}
.sub-mid{
    margin:0 auto;
	max-width:1100px;
}
.sub-link{
	cursor:pointer;
}
.sub-link:hover{
	text-decoration:underline;
}
.sub-all{
    margin: 30px auto 0 auto;
    max-width: 300px;
}
.sub-tit-h2{
    font-size: 1.3rem;
	font-weight: 500;
	margin:0;
}
.sub-card{
	border:1px solid #d2d2d2;
	padding:0 15px 0 15px;
	cursor:pointer;
	margin-bottom:20px;
	height: 54px;
}
.sub-txt{
	float:left;
	padding:12px 0 10px 0;
}
.sub-add{
	float:right;
	margin:10px 0 0 0;
	padding:3px 5px 5px 5px;
	cursor:pointer;
	background:#1F72EB;
}
.card-title{
	margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
	font-size: 1.25rem;
}
.sub-card:hover .card-title{
	text-decoration:underline;
}
.sub-card:hover .sub-add{
	background:#0B5ED7;
}
@media(min-width: 550px){
    .sub-all{
        width: 100%;
        max-width: unset;
        margin: 30px 0 0 0;
        display: grid;
        grid-template-columns: 47.5% 47.5%;
        grid-gap: 0% 5%;
    }
	.sub-tit-h2{
		font-size: 1.5rem;
	}
}
@media(min-width: 650px){
	.sub-htit{
		float:left;
	}
}
@media(min-width: 780px){
    .sub-all{
        grid-template-columns: 31.33% 31.33% 31.33%;
        grid-gap: 0% 3%;
    }
}
@media(min-width: 950px){
    .sub-all{
        grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
        grid-gap: 2%;
    }
}