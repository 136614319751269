.kre-nfd-week{
	margin-top:17px;
	color:#444;
}
.kre-nfd-add{
	margin:30px auto 0 auto;
	border:1px solid #aeaeae;
	padding:10px 20px;
	max-width:300px;
}
.kre-nfd-addt{
	float:left;
	font-weight:500;
	font-size: 1.2rem;
    cursor: pointer;
}
.kre-nfd-addi{
	float: right;
    margin: 0px 0 0 0;
    padding: 3px 5px 5px 5px;
    background: #1F72EB;
    cursor: pointer;
}
.kre-nfd-add:hover .kre-nfd-addt{
	text-decoration:underline;
}
.kre-nfd-add:hover .kre-nfd-addi{
	background:#0B5ED7;
}