.gm-wrd-bd{
	background-color: #fff;
    min-height: 300px;
    padding: 10px 10px 50px 10px;
}
.gm-wrd-letter{
	width: 100%;
    max-width: 300px;
    display: grid;
    grid-template-columns: 17.6% 17.6% 17.6% 17.6% 17.6%;
    grid-gap: 0% 3%;
	height:45px;
	margin: 5px auto 0 auto;
}
.gm-wrd-letter-elm{
	width:100%;
	height:100%;
	border:1px solid #ccc;
	display: flex;
    align-items: center;
    justify-content: center;
	text-transform:uppercase;
	font-size:1.4rem;
	font-weight:500;
}
.gm-wrd-keys{
	width: 100%;
    max-width: 462px;
    display: grid;
    /*grid-template-columns: 42px 42px 42px 42px 42px 42px 42px 42px 42px 42px;*/
	grid-template-areas: "elma elmb elmc elmd elme elmf elmg elmh elmi elmj";
    grid-gap:5px;
	height:50px;
	margin: 5px auto 0 auto;
}
.gm-wrd-keys-two{
	max-width: 418px;
    /*grid-template-columns: 42px 42px 42px 42px 42px 42px 42px 42px 42px;*/
	grid-template-areas: "elma elmb elmc elmd elme elmf elmg elmh elmi";
}
.gm-wrd-keys-three{
	max-width: 418px;
    /*grid-template-columns: 42px 42px 42px 42px 42px 42px 42px 42px 42px;*/
	grid-template-areas: "elma elmb elmc elmd elme elmf elmg elmh elmi";
}
.gm-wrd-key{
	display: flex;
    align-items: center;
    justify-content: center;
	text-transform:uppercase;
	font-size:1.1rem;
	font-weight:500;
	width:100%;
	height:100%;
	background-color:#D3D6DA;
	cursor:pointer;
}
.gm-wrd-key:hover{
	background-color:#C9CCD0;
}
.gm-wrd-key-spe{
	background:#fff;
	border:3px solid #D3D6DA;
}
.gm-wrd-key-spe:hover{
	background:#D3D6DA;
}
.gm-wrd-key-err{
	border:2px dashed #A82828;
}
.gm-wrd-keygrey{
	background-color:#4A4A4A;
	color:#fff;
}
.gm-wrd-keyyellow{
	background-color:#FFB600;
	color:#fff;
}
.gm-wrd-keygreen{
	background-color:#00895A;
	color:#fff;
}
.gm-keypad-grey{
	background-color:#808080;
}
.gm-keypad-yellow{
	background-color:#FFB600;
}
.gm-keypad-green{
	background-color:#00895A;
}
@media(min-width: 600px){
	.gm-wrd-letter{
		grid-template-columns: 19% 19% 19% 19% 19%;
		grid-gap: 0% 1.25%;
		height:60px;
	}
	.gm-wrd-letter-elm{
		font-size:1.8rem;
	}
	.gm-wrd-keys{
		grid-template-areas: unset;
		grid-template-columns: 42px 42px 42px 42px 42px 42px 42px 42px 42px 42px;
	}
	.gm-wrd-keys-two{
		grid-template-areas: unset;
		grid-template-columns: 42px 42px 42px 42px 42px 42px 42px 42px 42px;
	}
	.gm-wrd-keys-three{
		grid-template-areas: unset;
		grid-template-columns: 42px 42px 42px 42px 42px 42px 42px 42px 42px;
	}	
}