.gm-bd{
	background-color: #ffffff;
    min-height: 300px;
    padding: 30px 10px 50px 10px;
}
.gm-mid{
	margin: 0 auto;
    max-width: 1100px;
}
.gm-all{
    margin: 30px auto 0 auto;
    max-width: 300px;
}
.gm-tit-h2{
    font-size: 1.3rem;
	font-weight: 500;
	margin:0;
}
.gm-card{
	height:200px;
	cursor:pointer;
	margin-top:25px;
}
.gm-card:hover .gm-card-bd{
	text-decoration:underline;
}
.gm-card-hd{
	height:160px;
}
.gm-card-bd{
	padding-top:10px;
	height:30px;
	font-size:1.3rem;
	font-weight:500;
	color:#4A4A4A;
}
.gm-thm{
	background:#9E1E1E;
	display: flex;
    align-items: center;
    justify-content: center;
}
.gm-link{
	
}
.gm-hint{
	margin:5px 0 0 0;
}
@media(min-width: 500px){
	.gm-link{
		float:left;
	}
	.gm-hint{
		margin:0;
		float:right;
	}
}
@media(min-width: 550px){
    .gm-all{
        width: 100%;
        max-width: unset;
        margin: 30px 0 0 0;
        display: grid;
        grid-template-columns: 47.5% 47.5%;
        grid-gap: 0% 5%;
    }
	.gm-tit-h2{
		font-size: 1.5rem;
	}
}
@media(min-width: 780px){
    .gm-all{
        grid-template-columns: 31.33% 31.33% 31.33%;
        grid-gap: 0% 3%;
    }
	.gm-card{
		margin-top:0px;
	}
}
@media(min-width: 950px){
	/*
    .gm-all{
        grid-template-columns: 22.75% 22.75% 22.75% 22.75%;
        grid-gap: 3%;
    }
	*/
}