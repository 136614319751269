.vc-bd{
    background-color:#ffffff;
	min-height:300px;
	padding:30px 10px 50px 10px;
}
.vc-mid{
    margin:0 auto;
	max-width:1100px;
}
.vc-box{
	position:relative;
	margin: 20px 0 0 0;
    display: block;
}
.vc-side{
	display:none;
}
.vc-tit-h2{
    font-size: 1.3rem;
	font-weight: 500;
	margin:0;
}
.vc-thm{
	border:1px solid #D6D6D6;
	padding:10px 15px 15px 15px;
}
.vc-rgt-bx{
	margin-top:20px;
	background-color:#fff;
	border:1px solid #D6D6D6;
}
.vc-rgt-tit{
	padding:10px;
	border-bottom:1px solid #D6D6D6;
	font-size:1.2rem;
}
.vc-rgt-bd{
	padding:10px 10px 15px 10px;
	font-size:1rem;
}
.vc-new-li li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}
.vc-new-li {
  list-style-position: inside;
}
.vc-new-elm{
	color: #444;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.vc-new-elm:hover{
	color: #444;
	text-decoration:underline;
}
.vc-htit{
}
.vc-src{
	margin-top:10px;
	min-width:200px;
}
.vc-link{
	cursor:pointer;
}
.vc-link:hover{
	text-decoration:underline;
}
.vc-hd{
    margin:0 auto;
    max-width:400px;
    text-align: center;
}
.vc-rslt{
	float:left;
	font-size:18px;
	padding-top:0px;
	color:#727272;
}
.vc-all{
    margin: 30px auto 0 auto;
    max-width: 300px;
}
.vc-elm{
    margin-top: 25px;
    height:210px;
}
.vc-img{
    height:160px;
    cursor: pointer;
	border:1px solid #dee2e6;
}
.vc-tit{
    margin-top: 2px;
    font-size: 16px;
    font-weight: 500;
}
.vc-nb{
    font-size: 16px;
    color:#aeaeae;
}
@media(min-width: 550px){
    .vc-all{
        width: 100%;
        max-width: unset;
        margin: 20px 0 0 0;
        display: grid;
        grid-template-columns: 47.5% 47.5%;
        grid-template-rows: 230px 230px 230px 230px 230px 230px 230px 230px;
        grid-gap: 0% 5%;
    }
	.vc-tit-h2{
		font-size: 1.5rem;
	}
	.vc-rslt{
		padding-top:3px;
	}
}
@media(min-width: 650px){
	.vc-htit{
		float:left;
	}
	.vc-src{
		float:right;
		width:300px;
		margin-top:0px;
	}
}
@media(min-width: 780px){
    .vc-all{
        grid-template-columns: 31.33% 31.33% 31.33%;
        grid-template-rows: 230px 230px 230px 230px 230px 230px;
        grid-gap: 0% 3%;
    }
}
@media(min-width: 950px){
	.vc-box{
		display: grid;
		grid-template-columns: 65% 32.5%;
		grid-gap: 0% 2.5%;
	}
	.vc-side{
		display:block;
	}
	.vc-bx-mn{
		display: none;
	}
    .vc-all{
        grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
        grid-template-rows: 230px 230px 230px 230px;
        grid-gap: 0 2%;
    }
}