.gm-pzz-nb{
	margin:5px 0 0 0;
	font-size:0.95rem;
	font-weight:500;
	text-align:center;
}
.gm-pzz-bar{
	background-color:#fff;
	border:1px solid #ccc;
	height:15px;
	margin:3px 0 0 0;
}
.gm-pzz-ibar{
	background-color:#2675D1;
	height:100%;
	width:0%;
	transition: 2s;
}
.gm-pzz-box{
	width: 100%;
    max-width: 600px;
    display: block;
	margin: 20px auto 0 auto;
}
.gm-pzz-alpha{
	border:2px solid #444;
	background-color:#ffffff;
}
.gm-pzz-alpha-hd{
	display: flex;
    align-items: center;
    justify-content: center;
	border-bottom:0px solid #444;
	height:50px;
	font-weight:600;
	font-size:1.4rem;
	color:#aeaeae;
	text-transform:uppercase;
}
.gm-pzz-alpha-hdtxt{
	color:#444;
}
.gm-pzz-letter-bx{
	width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
	height:60px;
}
.gm-pzz-letter-bx-elm{
	display: flex;
    align-items: center;
    justify-content: center;
	font-weight:600;
	font-size:1.7rem;
	color:#444;
	text-transform:uppercase;
	border:2px solid #444;
	border-right:0;
	border-bottom:0;
	cursor:pointer;
}
.gm-pzz-letter-bx-elm:hover{
	background-color:#e8e8e8;
}
.gm-pzz-letter-picked{
	background: rgba(38, 117, 209, 0.4);
}
.gm-pzz-letter-picked:hover{
	background: rgba(38, 117, 209, 0.4);
}
.gm-pzz-btn{
	height:60px;
}
.gm-pzz-go{
	display: flex;
    align-items: center;
    justify-content: center;
	float:left;
	width:calc(100% - 79px);
	height:100%;
	border-top:2px solid #444;
	border-right:2px solid #444;
	font-weight:600;
	font-size:1.7rem;
	cursor:pointer;
}
.gm-pzz-go:hover{
	background-color:#e8e8e8;
}
.gm-pzz-del{
	display: flex;
    align-items: center;
    justify-content: center;
	float:left;
	width:79px;
	height:100%;
	border-top:2px solid #444;
	font-weight:600;
	font-size:2rem;
	cursor:pointer;
}
.gm-pzz-del:hover{
	background-color:#e8e8e8;
}
.gm-pzz-answ{
	border:2px solid #444;
	padding:10px;
	margin-top:30px;
	height:180px;
	overflow-y:auto;
}
.gm-pzz-answ-tit{
	color:#909090;
	text-align:center;
	font-weight:600;
	font-size:1.2rem;
}
.gm-pzz-answ-lst{
	display: grid;
    grid-template-columns: 47% 47%;
	grid-gap:0 6%;
	margin-top:2px;
}
.gm-pzz-answ-elm{
	padding:5px 0 5px 3px;
	border-top:1px solid #ccc;
	text-transform:uppercase;
}
@media(min-width: 600px){
	.gm-pzz-box{
		display: grid;
		grid-template-columns: 55% 40%;
		grid-gap: 0% 5%;
		margin: 40px auto 0 auto;
	}
	.gm-pzz-alpha-hd{
		height:60px;
		font-size:1.7rem;
	}
	.gm-pzz-answ{
		height:unset;
		margin-top:0;
		height:245px;
	}
}