.lk-blu004{ color:#0046BE; }
.lk-blu004:hover{ 
	color:#0046BE; 
	text-decoration:underline;
}
.wow-bx-bd{
	border: 10px solid #005CB9;
    padding: 15px 15px 20px 15px;
    text-align: center;
    margin: 20px auto 0 auto;
    max-width: 400px;
}
.wow-clrb-blu004{
	border-color:#0046BE;
}
.wow-clrt-blu004{
	color:#0046BE;
}
.wow-syl{
	color:#909090;
}
.wow-speaker{
	color:#4A4A4A;
	line-height: 1;
    font-size: 1.75rem;
	cursor:pointer;
	padding:0px 5px 5px 5px;
}
.wow-speaker:hover{
	background-color:#DEDEDE;
}
.wow-mute{
	color:#4A4A4A;
	line-height: 1;
    font-size: 1.75rem;
	padding:0px 5px 5px 5px;
}
.wow-date{
	color:#444444;
	font-size:1rem;
}