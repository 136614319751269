.gm-thm-bd{
	background-color: #fff;
    min-height: 300px;
    padding: 10px 10px 50px 10px;
}
.gm-thm-bx{
    display: block;
	margin: 15px auto 0 auto;
	max-width:250px;
}
.gm-thm-box-carret{
	display: flex;
	justify-content: center;
    gap: 5px;
    margin:5px auto 0 auto;
	height:40px;
}
.gm-thm-carret{	
	border: 1px solid #ccc;
    padding: 1px;
    height: 100%;
    width: 100%;
    max-width: 60px;
    flex: 0 0 11%;
	text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 500;
	text-align: center;
}
.gm-thm-man{
	position:relative;
    display: block;
	margin: 15px auto 0 auto;
	max-width:350px;
	height:250px;
	border:1px solid #ccc;
}
.gm-thm-keys{
	width: 100%;
    max-width: 462px;
    display: grid;
    /* grid-template-columns: 42px 42px 42px 42px 42px 42px 42px 42px 42px 42px; */
	grid-template-areas: "elma elmb elmc elmd elme elmf elmg elmh elmi elmj";
    grid-gap:5px;
	height:50px;
	margin: 5px auto 0 auto;
}
.gm-thm-keys-two{
	max-width: 418px;
    grid-template-areas: "elma elmb elmc elmd elme elmf elmg elmh elmi";
}
.gm-thm-keys-three{
	max-width: 327px;
    grid-template-areas: "elma elmb elmc elmd elme elmf elmg";
}
.gm-thm-key{
	display: flex;
    align-items: center;
    justify-content: center;
	text-transform:uppercase;
	font-size:1.1rem;
	font-weight:500;
	width:100%;
	height:100%;
	background-color:#D3D6DA;
	cursor:pointer;
}
.gm-thm-keye{
	width:100%;
	height:100%;
	background-color:#fff;
}
.gm-thm-key:hover{
	background-color:#C9CCD0;
}
.gmthm-drw-ft{
	position:absolute;
	bottom:0px;
	left:0;
	height:10px;
	background:#444;
	width:100px;
}
.gmthm-drw-pole{
	position:absolute;
	bottom:0px;
	left:0;
	height:100%;
	background:#444;
	width:10px;
}
.gmthm-drw-poleb{
	position:absolute;
	top:0px;
	left:0;
	height:10px;
	background:#444;
	width:100%;
}
.gmthm-drw-polec{
	position:absolute;
	top:34px;
	left:-8px;
	height:10px;
	background:#444;
	width:100px;
	transform: rotate(-45deg);
}
.gmthm-drw-poled{
	position:absolute;
	top:0px;
	left:80%;
	height:40px;
	background:#444;
	width:7px;
}
.gmthm-drw-bd-hd{
	position:absolute;
	border:7px solid #444;
	top:40px;
	left:69%;
	height:60px;
	width:60px;
	-moz-border-radius: 60px;
	-webkit-border-radius: 60px;
	border-radius: 60px;
}
.gmthm-drw-bd-bd{
	position:absolute;
	top:95px;
	left:79%;
	height:115px;
	background:#444;
	width:10px;
}
.gmthm-drw-bd-ara{
	position:absolute;
	top:115px;
	left:88%;
	height:70px;
	background:#444;
	width:10px;
	transform: rotate(-40deg);
}
.gmthm-drw-bd-arb{
	position:absolute;
	top:115px;
	left:70%;
	height:70px;
	background:#444;
	width:10px;
	transform: rotate(40deg);
}
.gmthm-drw-bd-lga{
	position:absolute;
	top:199px;
	left:88%;
	height:70px;
	background:#444;
	width:10px;
	transform: rotate(-40deg);
}
.gmthm-drw-bd-lgb{
	position:absolute;
	top:199px;
	left:70%;
	height:70px;
	background:#444;
	width:10px;
	transform: rotate(40deg);
}
.man-red{
	background-color:#CD0000;
}
.man-bdred{
	border-color:#CD0000;
}
.submg-l0{margin-left: 0;}
@media(min-width: 600px){
	.gm-thm-box-carret{
		height:50px;
	}
	.gm-thm-carret{
		font-size:1.8rem;
	}
	.gm-thm-man{
		height:380px;
		margin: 25px auto 0 auto;
	}
	.gm-thm-keys{
		grid-template-columns: 42px 42px 42px 42px 42px 42px 42px 42px 42px 42px;
	}
	.gm-thm-keys-two{
		grid-template-columns: 42px 42px 42px 42px 42px 42px 42px 42px 42px;
	}
	.gm-thm-keys-three{
		grid-template-columns: 42px 42px 42px 42px 42px 42px 42px;
	}	
}
@media(min-width: 700px){
	.gm-thm-bd{
		padding: 15px 10px 50px 10px;
	}
    .gm-thm-bx{
        width: 100%;
        max-width: unset;
        display: grid;
        grid-template-columns: 55% 40%;
        grid-gap: 0% 5%;
		margin: 30px 0 0 0;
		max-width:unset;
    }
}
@media(min-width: 10px){
	.gmthm-drw-poleb{
		left:0;
		height:10px;
		width:250px;
	}
	.gmthm-drw-polec{
		left:-8px;
		height:10px;
		width:100px;
	}
	.gmthm-drw-poled{
		left:222px;
		width:7px;
	}
	.gmthm-drw-bd-hd{
		left:205px;
		height:40px;
		width:40px;
	}
	.gmthm-drw-bd-bd{
		left:221px;
		height:95px;
		top:75px;
		width:8px;
	}
	.gmthm-drw-bd-ara{
		left:236px;
		top:90px;
		width:8px;
		height:52px;
	}
	.gmthm-drw-bd-arb{
		left:205px;
		top:92px;
		width:8px;
		height:50px;
	}
	.gmthm-drw-bd-lga{
		left:238px;
		top:160px;
		width:8px;
		height:50px;
	}
	.gmthm-drw-bd-lgb{
		left:205px;
		top:160px;
		width:8px;
		height:50px;
	}
	.gm-letter-ft{
		display:none;
	}
}
@media(min-width: 600px){
	.gmthm-drw-poleb{
		left:0;
		height:10px;
		width:250px;
	}
	.gmthm-drw-polec{
		left:-8px;
		height:10px;
		width:100px;
	}
	.gmthm-drw-poled{
		left:222px;
		width:7px;
	}
	.gmthm-drw-bd-hd{
		left:195px;
		height:60px;
		width:60px;
	}
	.gmthm-drw-bd-bd{
		left:219px;
		height:115px;
		top:95px;
		width:10px;
	}
	.gmthm-drw-bd-ara{
		left:240px;
		top:115px;
		width:10px;
		height:70px;
	}
	.gmthm-drw-bd-arb{
		left:197px;
		top:115px;
		width:10px;
		height:70px;
	}
	.gmthm-drw-bd-lga{
		left:240px;
		top:199px;
		width:10px;
		height:70px;
	}
	.gmthm-drw-bd-lgb{
		left:198px;
		top:199px;
		width:10px;
		height:70px;
	}
	.gm-letter-ft{
		display:none;
	}
}