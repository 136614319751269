.sel-cnt{
    border:1px solid #cccccc;
    padding:10px 15px;
    max-width:450px;
    display: grid;
    grid-template-columns: 90% 10%;
    cursor: pointer;
    position: relative;
    text-align: left;
}
.sel-txt{
    text-align: left;
}
.sel-arr{
    text-align: right;
	color:#b8b8b8;
}
.sel-mre{
    position: absolute;
    top: 44px;
    left: -1px;
    height: 212px;
    width: 100.5%;
    background-color: #fff;
    border: 1px solid #767676;
    z-index: 300;
}
.sel-inp{
    padding: 10px 10px 10px 10px;
    background-color: #fcfcfc;
    border-bottom: 1px solid #ccc;
}
.sel-inp-css{
    width:100%;
    height:35px;
    border: 1px solid #cccccc;
    padding:2px 5px;
}
.sel-opt{
    height:153px;
    overflow-y: auto;
}
.sel-opt-elm{
    padding:5px 10px;
}
.sel-opt-elm:hover{
    background-color: #f2f2f2;
}
@media(min-width: 650px){
    .inp-src-css{
        border-right:1px solid #000;
    }
    .inp-src-auto{
        width:98%;
    }    
}