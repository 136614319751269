.exp-bd{
    background-color:#f2f2f2;
	min-height:300px;
	padding:30px 10px 50px 10px;
}
.exp-mid{
    margin:0 auto;
	max-width:1100px;
}
.exp-all{
    width: 100%;
    margin: 40px auto 0 auto;
}
.exp-all-tit{
    cursor: pointer;
}
.exp-all-tit:hover{
    color:#A52A2A;
}
.exp-tit-h2{
    font-size: 1.3rem;
	font-weight: 500;
}
.exp-tit-res{
	float:right;
	padding-top:5px;
	font-size: 1rem;
}
.exp-bx{
	position:relative;
	margin: 20px 0 0 0;
    display: block;
}
.exp-side{
	display:none;
}
.exp-bx-mn{
	display: block;
	position: sticky;
    bottom: 50%;
    left: 100%;
    right: 0;
    /*margin: auto;*/
    width: 32px;
    height: 37px;
    background-color: #585858;
    border: 0px solid #ccc;
    padding: 5px 8px;
    color: #fff;
    cursor: pointer;
    z-index: 600;
    -webkit-box-shadow: 0px 0px 5px 0px rgb(68 68 68);
    -moz-box-shadow: 0px 0px 5px 0px rgba(68,68,68,1);
    box-shadow: 0px 0px 5px 0px rgb(68 68 68);
}
.exp-mn-bar{
    background-color: #fff;
    height: 3px;
    width: 100%;
    margin-top: 5px;
}
.exp-full-mn{
	position: fixed;
    top: 0;
    right: -300px;
    width: 100%;
    max-width: 300px;
    height: 100vh;
    background-color: #fff;
    border-left: 3px solid #ccc;
    padding: 20px 10px 0 10px;
    transition: 0.5s;
    z-index: 600;
}
.exp-full-mn-xx{
	position: absolute;
    top: -17px;
    left: 0px;
    color: #ccc;
    font-size: 20px;
    cursor: pointer;
}
.exp-full-mn-xx:hover{
	 color: #444;
}
.exp-full-mn-on{
	transition: 0.5s;
    right: 0;
}
.exp-elm{
	margin-top:25px;
	background-color:#fff;
	border:1px solid #D6D6D6;
	border-bottom:1px solid #D6D6D6;
}
.exp-tit{
	padding:20px;
	font-size:1.4rem;
	color:#007398;
	border-bottom:1px solid #D6D6D6;
}
.exp-tita{
	cursor:pointer;
}
.exp-tita:hover{
	text-decoration:underline;
}
.exp-dsc{
	padding:10px 20px 15px 20px;
}
.exp-badge{
	padding:10px;
}
.exp-dsc-lft{
	float:left;
	width:47%;
	margin-right:6%;
}
.exp-dsc-rgt{
	float:left;
	width:47%;
}
.exp-mn{
	font-size:1.1rem;
	font-weight:500;
}
.exp-thm{
	background-color:#fff;
	border:1px solid #D6D6D6;
	padding:10px 15px 15px 15px;
}
.exp-rgt-bx{
	margin-top:20px;
	background-color:#fff;
	border:1px solid #D6D6D6;
}
.exp-rgt-tit{
	padding:10px;
	border-bottom:1px solid #D6D6D6;
	font-size:1.2rem;
}
.exp-rgt-bd{
	padding:10px 10px 15px 10px;
	font-size:1rem;
}
.exp-badge-bx{
	padding:0px 20px 15px 20px;
	display: flex;
	align-items: right;
	justify-content: right;
	gap:10px;
	flex-flow: row wrap;
}
.exp-badge{
	background-color:#E2E8F0;
	color:#444;
	padding:5px 10px;
	font-size:0.95rem;
	font-weight:400;
}
.exp-badge-u{
	position:relative;
	background-color:#E2E8F0;
	border:1px solid #aeaeae;
	color:#444;
	padding:3px 30px 5px 10px;
	font-size:1.1rem;
	font-weight:400;
	cursor:pointer;
}
.exp-badge-ux{
	position:absolute;
	top:4px;
	right:7px;
}
.exp-badge-u:hover{
	background-color:#CED4DC;
}
.exp-all-elm{
    padding:20px;
    margin: 30px 0 0 0;
    background-color: #ffffff;
    border: 1px solid #ccc;
    height:112px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
    box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
}
.exp-elm-tagmr{
    margin-top: 10px;
}
.exp-elm-mr{
    float: left;
    color: #a4a4a4;
    cursor: pointer;
}
.exp-elm-mr:hover{
    color: #444;
}
.exp-elm-tag{
    float: right;
}
.exp-tag{
    background-color:#ffffff;
    border: 1px solid #6c757d;
    padding:3px 8px 4px 8px;
    font-size: 14px;
    color:#6c757d;
}
.exp-det{
    margin-top: 15px;
    font-size: 16px;    
}
.exp-det-sh{
    height: auto;   
}
.exp-subt{
    color:#444;
    font-weight: bold;    
}
.exp-new-elm{
	color: #444;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.exp-new-elm:hover{
	color: #444;
	text-decoration:underline;
}
.exp-new-li li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}
.exp-new-li {
  list-style-position: inside;
}
@media(min-width: 550px){
	.exp-tit-h2{
		font-size: 1.5rem;
		font-weight:500;
	}
	.exp-tit-res{
		padding-top:6px;
		font-size:1.1rem;
	}
}
@media(min-width: 850px){
    .exp-all{
        margin: 20px 0 0 0;
        display: grid;
        grid-template-columns: 47.5% 47.5%;
        grid-gap: 0% 5%;
    }
}
@media(min-width: 950px){
   .exp-bx{
		display: grid;
		grid-template-columns: 65% 32.5%;
		grid-gap: 0% 2.5%;
	}
	.exp-side{
		display:block;
	}
	.exp-bx-mn{
		display: none;
	}
}
.mg-t0{margin-top:0;}