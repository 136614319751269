.pg-cnt{
    margin-top: 40px;
}
.pg-bx{
    display: grid;
    grid-template-columns: 40px 55px 40px auto;
    grid-gap: 0 5px;
}
.pg-arr{
    background-color: #f3f4f5;
	border: 1px solid #ccc;
    text-align: center;
    color:#444;
    padding-top: 1px;
    cursor: pointer;
}
.pg-arr:hover{
	background-color: #DFE0E1;
    color:#000;
}
.pg-arr-off{
	background-color: #fff;
	border: 1px solid #ccc;
    text-align: center;
    color:#B8B8B8;
    padding-top: 1px;
	cursor: default;
}
.pg-arr-off:hover{
	color:#B8B8B8;
}
.pg-inp{
    text-align: center;
}
.pg-txt{
    text-align: center;  
    padding: 1px 0 0 5px; 
    font-size: 16px; 
}
.pg-inp-css{
    width: 100%;
    border: 1px solid #ccc;
    text-align: center;
}