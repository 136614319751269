.brw-hd{
	text-align:left;
}
.brw-shd{
	font-size:22px;
}
.brw-all{
	margin-top:30px;
}
.brw-tit-h2{
    font-size: 1.3rem;
	font-weight: 500;
	margin:0;
}
.brw-alpha-scll{
	margin:0 auto;
    width:100%;
	height: 105px;
	overflow: hidden;
	overflow-y: scroll;	
	border-bottom:1px solid #ccc;
}
.brw-alpha-grd{
    display: grid;
    grid-template-columns: repeat(8, 11.5%);
    gap: 5% 1%;
    height: 180px;
}
.brw-alpha-box-elm{
	display: flex;
    gap: 3px;
    overflow-x: hidden;
    border-right: 0px solid #ccc;
    margin: 0;
    transition: 2s;
}
.brw-alpha-elm{
	display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    height: 100%;
	color:#fff;
	background-color:#c40000;
	font-weight:600;
	font-size:18px;
    cursor: pointer;
    flex: 0 0 36.6px;
}
.brw-alpha-elm:hover{
	background-color:#9C0000;
}
.brw-alpha-elm-atv{
	display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    height: 100%;
	font-weight:600;
	font-size:18px;
    flex: 0 0 34.85px;
	
	color:#c40000;
	border:2px solid #c40000;
	background-color:#fff;
}
.brw-alpha-arr{
	display: flex;
    align-items: center;
    justify-content: center;
	border: 1px solid #ccc;
    padding: 1px;
    height: 100%;
	width:100%;
	z-index:200;
	background-color:#ffffff;
    cursor: pointer;
}
.brw-alpha-arr:hover{	
	background-color:#f2f2f2;
}
.brw-wait{
	text-align:center;
	padding:50px 0 30px 0;
}
.brw-list{
	padding:30px 0 20px 0;
}
.brw-list-elm{
	padding:10px 15px;
	border:1px solid #ccc;
	border-top:0;
	cursor:pointer;
}
.brw-list-elm-bd-t1{border-top:1px solid #ccc;}
.brw-list-elm:hover{
	background-color:#f2f2f2;
}
.brw-lst-elm-wrd{
	font-size: 1.5rem;
}
.brw-lst-elm-lnk{
	color: #555;
	font-weight:400;
	cursor:pointer;
}
.brw-lst-elm-lnk:hover{
	text-decoration:underline;
}
.brw-lst-elm-sb{
	font-size:1rem;
}
.brw-lst-two{
    display: grid;
	grid-template-columns: 100%;
    grid-column-gap: 0;
	grid-row-gap: 10px;
}
.brw-tab{
	border:1px solid #183153;
}
.brw-tab-hd{
	padding:7px 20px 11px 20px;
	background-color:#183153;
	color:#fff;
	font-size:1.5rem;
	font-weight:500;
}
.brw-tab-bd{
	padding:20px;
}
@media(min-width: 500px){
	.brw-alpha-grd{
		grid-template-columns: 6% 87% 6%;
		gap: 0.5%;
	}
	.brw-alpha-scll{
		height: 105px;
		width:85%;
		overflow-y: auto;
	}
	.brw-alpha-grd{
		grid-template-columns: repeat(10, 9%);
		gap: 5% 1%;
		height: 135px;
	}
}
@media(min-width: 600px){
	.brw-lst-two{
		display: grid;
		grid-template-columns: 47.5% 47.5%;
		grid-column-gap: 5%;
		grid-row-gap: 20px;
	}
	.brw-alpha-scll{
		height: 105px;
		width:85%;
		border:0;
	}
	.brw-alpha-grd{
		grid-template-columns: repeat(13, 6.7%);
		gap: 5% 1%;
		height: 90px;
	}	
	.brw-tit-h2{
		font-size: 1.5rem;
	}
}
@media(min-width: 800px){
	.brw-alpha-grd{
		grid-template-columns: 3% 93% 3%;
		gap: 0.5%;
	}
	.brw-alpha-scll{
		height: 60px;
		width:100%;		
	}
	.brw-alpha-grd{
		grid-template-columns: repeat(26, 3.5%);
		gap: 0.52%;
		height: 45px;
	}
}