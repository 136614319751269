.inp-ac-bx{
	display: grid;
    grid-template-columns: 84% 14%;
    grid-gap: 0% 2%;
	border:1px solid #dee2e6;
	position:relative;
}
.inp-ac-fld{
}
.inp-ac-icn{
	width: 100%;
    height: 100%;
    font-size: 22px;
    color: #b8b8b8;
	display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
	border-left:1px solid #dee2e6;
}
.inp-ac-icn:hover{
	background-color:#f2f2f2;
}
.inp-auto-bx{
	position: absolute;
    top: 41px;
    left: -1px;
    width: 87%;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-top: 0px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 500;	
}
.inp-auto-elm{
	padding: 5px 10px;
    border-top: 1px solid #f2f2f2;
    color: #4A4A4A;
    cursor: pointer;	
}
.inp-auto-elm:hover{
	background-color:#f2f2f2;
}