.ft-cnt{
    background-color:#fff;
    padding:30px 0 30px 0;
	border-top:2px solid #E0E0E0;
}
.ft-mid{
    max-width: 900px;
    margin:0 auto;
    padding:0 10px;
}
.ft-nm{
    text-align: center;
}
.ft-nmlh{
    line-height: 0.9;
}
.ft-gd{
    margin-top: 20px;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 100%;
    text-align: center;
}
.ft-elm{
    height:28px;
    border-right: 0px solid #eaeaea;    
}
.ft-elm-spn{
    cursor: pointer;
}
.ft-elm-spn:hover{
    text-decoration: underline;
}
.ft-lst{
    font-size: 14px;
}
.ft-lng{
	margin:10px auto 0px auto;
    border: 1px solid #ccc;
    background-color: #F5F5F5;
    padding:1px 5px 2px 5px;
    color: #444;
    cursor: pointer;
    height:28px;
    width:131px;
    position: relative;
}
.ft-lng-mr{
    position: absolute;
    top: -80px;
    left: -1px;
    width:131px;
    background-color:#ffffff;
    border: 1px solid #ccc;
    padding: 5px 0 5px 0;
}
.ft-lng-mr-elm{
    padding: 5px 10px;
}
.ft-lng-mr-elm:hover{
    background-color: #f2f2f2;
}
.ft-crgt{
    margin-top: 25px;
    text-align: center;
}
.ft-flg-df{
    float: left;
    padding-right:10px;
    height:100%;
}
.ft-flg-txt{
    float: left;
    padding-right:10px;  
}
.ft-flg-arr{
    float: left;    
}
@media(min-width: 950px){
    .ft-nm{
        text-align: left;
    }
    .ft-gd{
        grid-template-columns: 20% 15% 21% 24% 20%;
        grid-gap: 0;
    }
    .ft-lng{
        float: right;
		margin: 4px 0 0 0;
    }
    .ft-crgt{
		margin-top: 10px;
        float: left;
    }
}