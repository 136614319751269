.gmr-box{
	position:relative;
	padding:20px;
	background-color:#fff;
	margin:20px auto 0 auto;
	max-width:400px;
}
.gmr-x{
	position:absolute;
	top:-32px;
	right:0px;
	cursor:pointer;
	color:#aeaeae;
	font-size: 1.3rem;
}
.gmr-x:hover{
	color:#000000;
}
.gmr-box-tit{
	font-size:1.4rem;
	font-weight:500;
	text-align:center;
}
.gmr-box-check{
	display: flex;
    align-items: center;
    justify-content: center;
	background-color:#2DCC70;
	margin:50px auto 0 auto;
	max-width:80px;
	height:80px;
	color:#fff;	
	font-size:2.5rem;
	font-weight:500;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(45,204,112,1);
	-moz-box-shadow: 0px 0px 10px 0px rgba(45,204,112,1);
	box-shadow: 0px 0px 10px 0px rgba(45,204,112,1);
}
.gmr-box-check-lost{
	display: flex;
    align-items: center;
    justify-content: center;
	background-color:#FF2851;
	margin:50px auto 0 auto;
	max-width:80px;
	height:80px;
	color:#fff;	
	font-size:2.5rem;
	font-weight:500;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(255,40,81,1);
	-moz-box-shadow: 0px 0px 10px 0px rgba(255,40,81,1);
	box-shadow: 0px 0px 10px 0px rgba(255,40,81,1);
}
.gmr-box-msg{
	margin:40px auto 0 auto;
	max-width:150px;
	color:#444;
	text-align:center;
	font-size:1.1rem;	
}
.gmr-box-play{
	margin:40px auto 15px auto;
	max-width:220px;
	color:#444;
	border:2px solid #2DCC70;
	text-align:center;
	font-size:1.2rem;
	height:34px;
	cursor:pointer;
}
.gmr-box-play:hover{
	background-color:#f2f2f2;
}

.gmr-box-play-lost{
	border:2px solid #FF2851;
}
.gmr-box-close{
	margin:0px auto 25px auto;
	max-width:220px;
	color:#444;
	border:2px solid #444;
	text-align:center;
	font-size:1.2rem;
	height:34px;
	cursor:pointer;
}
.gmr-box-close:hover{
	background-color:#f2f2f2;
}
.modal-dialog-gm {
    max-width: 500px;
}
.modal-body {
    max-height: unset;
}
@media(min-width: 500px){ 
	.gmr-x{
		right:-15px;
	}
}