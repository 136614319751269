.vc-bx{
	padding:0;
    min-height:100px;
    max-height:150px;
    background-color: #f2f2f2;
	border: 1px solid #ccc;
	overflow:hidden;
}
.vc-hd-img{
	float:left;
	width:200px;
	padding:5px;
	border-right: 1px solid #ccc;
}
.vc-hd-txt{
	float:left;
	width:calc(100% - 200px);
	padding:5px 20px 0 15px;
}
.vc-bx-nb{
    font-size: 16px;
}
.vc-lst{
    width: 100%;
    margin: 30px auto 0 auto;
}
.vc-lst-elm{
    border-top:1px solid #ccc;
    padding:25px 0;
}
.vcv-link{
	cursor:pointer;
}
.vcv-link:hover{
	text-decoration:underline;
}
@media(min-width: 750px){
    .vc-lst{
        margin: 30px 0 0 0;
        display: grid;
        grid-template-columns: 47.5% 47.5%;
        grid-gap: 0% 5%;
    }
}