.wrd-week-bd{
	background-color: #fff;
    min-height: 300px;
    padding: 10px 10px 50px 10px;
}
.wrd-week-mid{
	margin:30px auto 60px auto;
	max-width:1100px;
}
.wrd-week-box{
	position:relative;
	max-width:1000px;
	border:8px solid #C34848;
	padding:20px 40px 30px 40px;
	margin:0 auto;
}
.wrd-week-type{
	margin-top:20px;
	border-top:10px solid #e8e8e8;
	padding-top:5px;
	float:left;
	font-style: italic;
	font-size:1.5rem;
}
.wrd-week-def{
	padding-top:10px;
	font-size:1.4rem;	
}
.wrd-week-tit{
	font-size:2.5rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.wrd-week-tim{
	font-size:1.1rem;
}
.wrd-week-arr{
	position:absolute;
	top:110%;
	left: 15px;
	transform: translate(-50%, -50%);
	color:#444;
	font-size:3rem;
	cursor:pointer;
	line-height:0;
}
.wrd-week-arrr{
	right:-20px;
	left:unset;
	text-align:right;
}
.wrd-week-arr:hover{
	color:#C34848;
}
@media(min-width: 900px){
	.wrd-week-tim{
		font-size:1.5rem;
	}
	.wrd-week-tit{
		font-size:3.5rem;
	}
}
@media(min-width: 1160px){
	.wrd-week-mid{
		margin:30px auto 0px auto;
	}
	.wrd-week-arr{	
		top:50%;
		left: -50px;
	}
	.wrd-week-arrr{
		right:-113%;
	}
}