.fld-wrd-inp{
    color: #383838;
    padding: 0px 10px;
    width: 100%;
    height: 40px;
    border: 1px solid #dee2e6;
    line-height: 3;
}
.fld-wrd-txtara{
	width:100%;
	max-width:700px;
	height:110px;
	padding:10px;
	border:1px solid #ccc;
	color:#545454;
}
.fld-wrd-sel{
	border:1px solid #ccc;
    width: 100%;
    height:  35px;
    font-size: 18px;
	padding:0 5px;
	color:#545454;
}
.fld-wrd-seltxt{
	display: flex;
	gap:20px;
}
.fld-wrd-div{
	border:1px solid #ccc;
	padding:10px;
	height:200px;
}
.fld-wrd-div:focus{
	outline: none;
}
.fld-wrd-inps{
	display: flex;
	gap:15px;
}
.fld-inps-nb{
	width: 20px;
	display: flex;
	align-items: center;
}
.fld-inps-inp{
	width: calc(100% - 70px);
}
.fld-wrd-seltxts{
	display: flex;
}
.fld-wrd-selnb{
	display: flex;
    align-items: center;
	width:40px;
	font-size:1.1rem;
	color:#909090;
}
.fld-wrd-selsel{
	width:100%;
	max-width:200px;
	color:#909090;
}
.fld-wrd-seltxti{
	width:calc(100% - 35px);
}
.fld-wrd-add{
	border:1px solid #ccc;
	color:#aeaeae;
	font-size: 14px;
}
.fld-wrd-x:hover{
	border:1px solid #CD0419;
	color:#CD0419;
	background-color:#fff;
	font-weight:600;
}
.fld-badge-span{
	padding:5px 10px;
	margin-right:10px;
	background-color:#eeeff0;
	border:1px solid #ccc;
	color:#444;
}