.hd{
	height: 56px;
    padding: 3px 10px 0 10px;
	border-bottom:1px solid #ccc;
	/*
	margin:0 auto;
	max-width:1300px;
	border-right:1px solid #ccc;
	*/
}
.hd-shd{
    -webkit-box-shadow: 0px 2px 5px 0px rgba(204,204,204,1);
    -moz-box-shadow: 0px 2px 5px 0px rgba(204,204,204,1);
    box-shadow: 0px 2px 5px 0px rgba(204,204,204,1);
}
.hd-logo{
	float:left;
	width:40px;
	height:100%;
	padding:4px 0;
}
.hd-logot{
    display: none;
	float:left;
	padding:7px 0 0 20px;
	font-size:26px;
	font-weight:700;
	width:210px;
}
.hd-br{
	float:left;
	padding:6px 0 0 6px;
	font-size: 35px;
	width:45px;
    text-align: center;
    position: relative;
    cursor: pointer;
	line-height:1;
}
.hd-br-mn{
    position: absolute;
    top:52px;
    left:-25px;
    width:232px;
    background-color: #ffffff;
    border:1px solid #cccccc;
    height:235px;
    z-index: 500;    
    -webkit-box-shadow: 0px 2px 5px 0px rgba(204,204,204,1);
    -moz-box-shadow: 0px 2px 5px 0px rgba(204,204,204,1);
    box-shadow: 0px 2px 5px 0px rgba(204,204,204,1);
    padding:0px 15px;
    text-align: left;
}
.hd-br-mn-elm{
    margin-top: 10px;
    font-size: 18px;
    text-decoration:none;
    color:inherit;
    line-height: 1.45;
}
.hd-br-mn-elm:hover{
    color:#E11414;
}
.hd-br-lnk{
    text-decoration:none;
    color:inherit;
}
.hd-br-lnk:hover{
    text-decoration:none;
    color:inherit;
}
.hd-search{
	float:left;
    width:calc(100% - 85px);
	padding:5px 0 0 10px;
}
.hd-src-a{
	float:left;
	border:#000 solid 1px;
	border-right:0;	
	width:calc(100% - 50px);
	padding:4px 0px 4px 10px;
	height: 40px;
    position: relative;
    font-size: 18px;
}
.hd-src-auto{
    position: absolute;
    top:43px;
    left:-1px;
    width:102%;
    background-color: #ffffff;
    border:1px solid #000000;
    border-top:0px;
    height:300px;
    z-index: 500;
}
.hd-src-auto-elm{
    padding:7px 10px;
    border-top:1px solid #f2f2f2;
    color:#4A4A4A;
    cursor:pointer;
}
.hd-src-auto-elm:hover{
    background-color: #f2f2f2;
}
.hd-src-inp{
	border:0px;
	border-right:0px solid #000;	
	width:100%;
	height:100%;
	color:#212529;
}
.hd-src-sel{
	border:0px;	
	width:100%;
	height:100%;
    font-size: 18px;
	background: #ffffff;
}
.hd-src-b{
    display: none;
	float:left;
	border:1px solid #000;
	border-left:0;	
	border-right:0;	
	width:140px;	
	height: 45px;
}
.hd-src-c{
	float:left;
	border:1px solid #000;
	width:50px;	
	height: 40px;
	border-left:0;
	padding: 3px 3px 3px 5px;
}
.hd-src-btn{
	width:100%;
	height:100%;
    background-color: #ff364b;
	font-size:26px;
	color:#fff;
	cursor:pointer;
}
.hd-src-icn{
	width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
	max-width:18px;
}
.hd-mn{
	float:right;
	height:100%;
}
.hd-mn-a{
    display: none;
	float:left;
	padding:16px 1px;
	font-size: 16px;
    margin:0 14px;
    cursor: pointer;
}
.hd-mn-a:hover{
    color:#000;
    text-decoration: underline;
}
.hd-mn-b{
    display: none;
	float:left;
	margin:14px 0px 16px 15px;
	font-size: 1rem;
	border:1px solid #ccc;
	width:35px;
	height:30px;
    cursor: pointer;
    color:#aeaeae;
	align-items: center;
    justify-content: center;
}
.hd-mn-b:hover{
    color:#868686;
    background-color: #f2f2f2;;
}
@media(min-width: 650px){
	.hd{
		height: 65px;
	}
	.hd-logo{
		width:50px;
	}
	.hd-search{
		width:calc(100% - 95px);
	}
	.hd-br{
		padding:9px 0 0 6px;
	}
	.hd-br-mn{
		top:63px;
	}
    .hd-src-a{
        width:calc(100% - 205px);
        padding: 4px 10px;
		height: 45px;
    }
	.hd-src-c{
		width: 65px;
		height: 45px;
    }
	.hd-src-icn{
		max-width:26px;
	}
    .hd-src-inp{
        border-right:1px solid #000;
    }
    .hd-src-auto{
        width:98%;
    }
    .hd-src-b{
        display: block;
    }
    
}
@media(min-width: 900px){
	.hd{
		padding: 3px 20px 0 20px;
	}
    .hd-search{
        padding:7px 0 0 20px;
    }
}
@media(min-width: 1140px){
	.hd-logo{
		width:50px;
	}
    .hd-logot{
        display: block;
    }
    .hd-br{
        text-align: left;
		width:60px;
		padding:9px 0 0 6px;
    }
    .hd-search{
        width: auto;
    }
    .hd-src-a{
        width:500px;
    }
    .hd-src-b{
        width:140px;
    }
    .hd-src-c{
        width:65px;
    }
}
@media(min-width: 1180px){
    .hd-mn-b{
        display: flex;
    }
}
@media(min-width: 1540px){    
    .hd-src-a{
        width:500px;
    }
    .hd-mn-a{
        display: block;
    }
}