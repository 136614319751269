.inp-src-cnt{
    height: 100%;
}
.inp-src-css{
	border:0px;
	border-right:0px solid #000;	
	width:100%;
	height:100%;
	color:#212529;
}
.inp-src-hmcss{
    padding: 5px;
    width: 100%;
    border: 0px;
    font-size: 20px;
    line-height: 0;
    height: 100%;
    color: #757575;
}
.inp-src-auto{
    position: absolute;
    top:33px;
    left:-1px;
    width:102%;
    background-color: #ffffff;
    border:1px solid #000000;
    border-top:0px;
    max-height:300px;
	overflow-y:auto;
    z-index: 500;
}
.inp-hmsrc-auto{
    position: absolute;
    top:39px;
    left:-1px;
    width:100.4%;
    background-color: #ffffff;
    border:1px solid #000000;
    border-top:0px;
    max-height:300px;
	overflow-y:auto;
    z-index: 400;
	text-align:left;
}
.inp-src-auto-elm{
    padding:7px 10px;
    border-top:1px solid #f2f2f2;
    color:#4A4A4A;
    cursor:pointer;
}
.inp-src-auto-elm:hover{
    background-color: #f2f2f2;
}
.inp-src-auto-elm-link{
    color:#4A4A4A;
}
.inp-src-auto-elm-link:hover{
    color:#4A4A4A;
	text-decoration:underline;
}
/** lazyload **/
/* 
wave effect animation 
activated by adding a '.wave' class to the src-ft-wait
*/
.src-ft-wait.lazyload-wave div{
    animation: wave 1s infinite linear forwards;
    -webkit-animation:wave 1s infinite linear forwards;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
}
@keyframes wave{
    0%{ background-position: -468px 0 }
    100%{ background-position: 468px 0 }
}
@-webkit-keyframes wave{
    0%{ background-position: -468px 0 }
    100%{ background-position: 468px 0 }
}
.src-ft-wait .src-ft-wait-lg, 
.src-ft-wait .src-ft-wait-sm{
	background-color:#eaeaea;
}
.src-ft-wait-lg{
	height:15px;
}
.src-ft-wait-sm{
	height:25px;
	margin:15px 15px;
}
/** end lazyload **/
@media(min-width: 650px){
    .inp-src-css{
        border-right:1px solid #000;
    }
    .inp-src-auto{
        width:98%;
    }
	.inp-hmsrc-auto{
		top:50px;
	}
	.inp-src-auto{
		top:43px;
	}
}