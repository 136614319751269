.pag-bd{
    background-color:#ffffff;
	min-height:300px;
	padding:30px 10px 50px 10px;
}
.pag-mid{
	margin:0 auto;
	max-width:1100px;
}
.pag-404-mid{
	margin:0 auto;
	max-width:350px;
}
.pag-404{
	display: grid;
    grid-template-columns: 33% auto 33%;
	grid-gap:0%;	
}
.pag-404-nb{
	font-size:10rem;
	line-height:1;
	color:#C32430;
}
.pag-404-icn{
	display: flex;
    align-items: center;
    justify-content: center;
	padding-top:20px;
}
.pag-404-sub{
	margin-top:20px;
	font-size:2rem;
	line-height:1;
	color:#C32430;
}
.pag-404-txt{
	margin-top:20px;
	font-size:1.7rem;
	color:#444;
}
.pag-menu{
	border:1px solid #ccc;
	padding:10px;
	font-size:1.5rem;
	line-height:1;
	cursor:pointer;
	height:47px;
	position:relative;
}
.pag-menu:hover{
	background-color:#f2f2f2;
}
.pag-menu-bx{
	position:absolute;
	border:1px solid #ccc;
	background:#fff;
	z-index:100;
	top:47px;
    left: -1px;
    width: 250px;
}
.pag-menu-elm{
	cursor:pointer;
	padding:10px 10px;
	background:#fff;
	font-size:1rem;
}
.pag-menu-elm:hover{
	background-color:#f2f2f2;
}
@media(min-width: 350px){
	.pag-404-mid{
		max-width:350px;
	}
	.pag-404-nb{
		font-size:12rem;
	}
	.pag-404-sub{
		margin-top:20px;
		font-size:3rem;
	}

}
@media(min-width: 600px){
	.pag-404-mid{
		max-width:600px;
	}
	.pag-404-nb{
		font-size:20rem;
	}
	.pag-404-sub{
		margin-top:20px;
		font-size:5rem;
	}
}