.exprv-bd{
	background-color: #f2f2f2;
	padding: 30px 10px 50px 10px;
}
.exprv-cnt{
	border:1px solid #D6D6D6;
	background-color:#fff;
	padding:20px;
}
.exprv-tit{
	border-left:2px solid #ccc;
	padding:0 0 5px 15px;
	font-size:1.2rem;
	font-weight:500;
}
.exprv-dsc{
	margin-top:20px;
	font-size:1.1rem;
}
.expr-li{
	font-size:1.1rem;
}
.expr-bdg-bx{
    padding: 20px 0px 0px 0;
    display: flex;
    gap: 10px;
	flex-flow: row wrap;
}
.expv-link{
	cursor:pointer;
}
.expv-link:hover{
	text-decoration:underline;
}
.vc-bx{
    padding:30px;
    background-color: #f2f2f2;
}
.vc-bx-nb{
    font-size: 18px;
}
.vc-lst{
    width: 100%;
    margin: 30px auto 0 auto;
}
.vc-lst-elm{
    border-top:1px solid #ccc;
    padding:25px 0;
}
@media(min-width: 750px){
    .vc-lst{
        margin: 30px 0 0 0;
        display: grid;
        grid-template-columns: 47.5% 47.5%;
        grid-gap: 0% 5%;
    }
}