.df-hd{
    height:155px;
	background-color:#183153;
}
.df-hd-mid{
    margin:0 auto;
	max-width:1100px;
	padding: 20px 10px;
}
.df-wrd{
    font-family: 'Trebuchet MS';
    font-size: 38px;
    color: #fff;
    font-weight: 700;
    text-shadow: 0px 2px 4px rgb(46 46 46 / 90%);
	text-align:center;
}
.df-wrd-stat{
    font-size: 16px;
    color: #fff;
	display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 0 10px;
	max-width:280px;
	margin:0 auto;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align:center;
}
.df-wrd-stat-elm{
    /*float:left;*/
}
.df-wrd-stat-sepsec{
	display:none;
}
.df-wrd-stat-val{
    font-weight:600;
}
.df-wrd-stat-sep{
	font-size: 22px;
	line-height: 1;
}
.df-wrd-stat-lab{
    color:#A2A3A4;
}
.df-bd{
    background-color:#f2f2f2;
	min-height:300px;
	padding:17px 10px 110px 10px;
}
.df-mid{
    margin:0 auto;
	max-width:1100px;
}
.df-bd-lft{
	width:100%;
}
.df-bd-rgt{
	position:relative;
}
.df-wrd-box{
    padding:20px;
	background-color:#ffffff;
}
.df-wrd-tit{
    padding:0 0 10px 0;
	border-bottom:1px solid #aeaeae;
	background: #f2f2f2;
    padding: 8px 0 5px 10px;
}
.df-wrd-oth{
    float:left;
	margin-bottom:3px;
}
.df-wrd-edt{
    float:right;
	margin:3px 10px 0 0;
	font-size:0.85rem;
	color:#aeaeae;
}
.df-wrd-edt-lk{
	color:#727272;
	cursor:pointer;
}
.df-wrd-edt-lk:hover{
	text-decoration:underline;
}
.df-wrd-nb{
    float:left;
	color:#aeaeae;
	margin-left: 5px;
    line-height: 0.3;
	padding-top: 3px;
}
.df-wrd-nbt{
    line-height: unset;
}
.df-wrd-arr{
    float:right;
    padding:15px 0 0 0;
    cursor: pointer;
}
.df-bx-badge{
    display:flex;
	padding:15px 0 0 0;
	flex-wrap:wrap;
	gap:20px;
}
.df-wrd-badge{
    border:1px dashed #d9d9d9;
	padding:5px 15px;
	color:#000000d9;
	cursor:pointer;
}
.df-wrd-badge:hover{
    border:1px dashed #aeaeae;
	text-decoration:underline;
	background:#fcfcfc;
}
.df-wrd-adef{
    margin:10px 0 0 15px;
	font-size:16px;
	font-weight:400;
}
.df-def-nb{
    float:left;
	color:#aeaeae;
	width:30px;
	padding-top: 1px;
}
.df-def-txt{
    float:left;
	width:calc(100% - 30px);
	font-size: 1.2rem;
    line-height: 1.3;
}
.df-def-see{
    margin:15px 0 0 0;
	color:#909090;
	font-size:15px;
}
.df-def-see-txt{
    color:#212529;
}
.df-def-typ{
    margin:10px 0 0 0;
	color:#909090;
}
.df-def-ftxt{
    margin-top:12px;
	font-size:16px;
	font-weight:400;
}
.df-wrd-sm{
    color: #4A4A4A;
	font-size: 14px;
}
.df-wrd-tit-mr{
    background: transparent;
	border:0;
	padding:0;
}
.df-wrd-nm{
    float:left;
	margin-right:5px;
}
.df-wrd-rgt{
    position:unset;
	width:100%;
}
.df-wrd-pro-rsp{
    font-size: 18px;
    padding:0 0 15px 0;
}
.df-wrd-pro{
    margin-top:40px;
	background-color:#fff;
	border:1px solid #ccc;
}
.df-wrd-pro-snd{
    display: none;
}
.df-pro-snd{
    padding:20px;	
	text-align:center;
}
.df-pro-syl{
	font-size:22px;
    font-weight: 600;
}
.df-pro-syl-na{
	color:#aeaeae;
}
.df-pro-talk-nosyl{
	color:#727272;
	padding-top:1px;
}
.df-pro-talk-rsp{
    line-height: 1;
    font-size: 22px;
	cursor:pointer;
	padding:0px 5px 6px 5px;
}
.df-pro-talk-rsp:hover{
	background-color:#DEDEDE;
}
.df-pro-talk{
    font-size:30px;
	line-height: 1.15;
    margin:10px 0 0 0;
}
.df-talk-btn{
    background:#fff;
    border:1px solid #505763;
	text-align:center;
    padding:3px 0 5px 0;
    cursor:pointer;
    width:100%;
    box-shadow: 0px 10px 14px -7px #cccccc;
	height: 50px;
}
.df-talk-btn-anim{
	position: relative;
	display: inline-block;
	background-image: linear-gradient(transparent, transparent);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: 50% 100%;
	transition: background-size .3s, color .5s;
}
.df-talk-btn-anim:hover {
	background-image: linear-gradient(#EAEAEA, #EAEAEA);
	background-size: 100% 100%;
}
.df-pro-txt{
    font-size:16px;
	font-weight:600;
	color:#909090;
    margin:10px 0 0 0;
}
.df-pro-like{
    font-size: 14px;
    cursor: pointer;
}
.df-wrd-frq{
    padding:20px;
}
.df-frq-tit{
    font-weight: 600;
}
.df-frq-br{
    margin-top: 12px;
    display: grid;
    grid-template-columns: 18.4% 18.4% 18.4% 18.4% 18.4%;
    grid-gap: 2%;
}
.df-frq-lgd{
    margin-top: 7px;
    font-size: 14px;
    color:#8e8e8e;
}
.df-frq-bx{
    border:1px solid #444;
    background-color: #fff;
    height:14px;
}
.df-frq-fill{
    background-color: #0087C2;
    height: 100%;
    width:100%;
}
.df-ex-nb{
    float: left;
    color: #aeaeae;
    width: 25px;
	font-size: 1.1rem;
	padding-top:2px;
}
.df-ex-txt{
    float: left;
    width: calc(100% - 25px);
	font-size:1.2rem;
}
@media(min-width: 550px){
	.df-wrd{
		font-size: 48px;
		text-align:left;
	}
	.df-wrd-stat{
		display: grid;
		grid-template-columns: auto auto auto auto auto auto auto;
		grid-gap: 0 10px;
		max-width:550px;
		text-align:left;
		margin:0;
	}
	.df-wrd-stat-sepsec{
		display:block;
	}
}	
@media(min-width: 1000px){ 
    .df-wrd-rgt{
        position:absolute;
        top:-170px;
        left:0;
        width:100%;
    } 
    .df-wrd-pro-rsp{
        display: none;
    }  
    .df-wrd-pro-snd{
        display: block;
    } 
    .df-wrd-pro{
        margin-top:20px;
    }
    .df-bd-lft{
        float:left;
        width:calc(100% - 330px);
        margin-right:30px;
    }
    .df-bd-rgt{
        float:right;
        width:300px;
    }
}
@media(min-width: 1120px){    
    .df-hd-mid{
        padding: 20px 0px;
    }    
    .df-bd{
        padding:30px 0 110px 0;
    }
}